@import '../../../scss/utilities';

.body {
  font-family: $ue-body-font;
  font-size: $ue-font-size-8;

  &_centered {
    text-align: center;
  }

  &_padded {
    padding-bottom: $grid-gutter-width;
  }

  h1,
  h2 {
    font-family: $ue-header-font;
    padding-top: $grid-gutter-width * 3;
    padding-bottom: $grid-gutter-width;
    position: relative;
    text-align: center;
    @include media-breakpoint-up(lg) {
      text-align: left;
    }

    &:before {
      content: '';
      border-top: $ue-dashed-border;
      position: absolute;
      top: $grid-gutter-width;
      left: 0;
      right: 0;
    }
  }
  h3,
  h4,
  h5,
  h6 {
    font-family: $ue-header-font;
    padding-top: $grid-gutter-width;
    padding-bottom: $grid-gutter-width;
    text-align: center;
    @include media-breakpoint-up(lg) {
      text-align: left;
    }
  }

  p {
    font-family: $ue-body-font;
    margin-bottom: $grid-gutter-width;
    &:first-of-type {
      // mimic lead styles
      font-size: $ue-font-size-8;
      @include media-breakpoint-up(md) {
        font-size: $ue-font-size-7;
      }
    }
  }
}
